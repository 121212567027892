.head-on-white {
  text-align: center;
  font-size: 40px;
  line-height: 40px;
}

.header-on-white-policy {
  max-width: 790px;
  color: #000;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  font-size: 40px;
  font-weight: 400;
  line-height: 45px;
  display: block;
}

.rich-text-block-8 {
  /* width: 650px; */
  /* max-width: 100%; */
  /* color: #000; */
  /* text-align: left; */
  /* margin-top: 40px; */
  /* margin-left: auto; */
  /* margin-right: auto; */
  /* font-size: 16px; */
  /* line-height: 26px; */
  /* display: block; */
}
