.border-bottom {
  border-bottom: 1px solid #f2f2f2 !important;
}
.border-top {
  border-top: 1px solid #f2f2f2 !important;
}

$color_1: rgba(var(--color-default-rgb), 0.8);
$color_2: var(--color-secondary);
$color_3: var(--color-inverse);
$color_4: var(--color-primary);
$background-color_1: var(--color-primary);
$background-color_2: rgba(var(--color-primary-rgb), 0.9);

.features {
  .features-item {
    color: $color_1;
    h3 {
      color: $color_2;
      font-weight: 700;
      font-size: 26px;
    }
    .btn-get-started {
      background-color: $background-color_1;
      color: $color_3;
      padding: 8px 30px 10px 30px;
      border-radius: 4px;
      &:hover {
        background-color: $background-color_2;
      }
    }
    ul {
      list-style: none;
      padding: 0;
      li {
        padding-bottom: 10px;
        display: flex;
        align-items: flex-start;
        &:last-child {
          padding-bottom: 0;
        }
      }
      i {
        font-size: 20px;
        padding-right: 4px;
        color: $color_4;
      }
    }
    img {
      border: 6px solid var(--color-box-background);
      box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.1);
    }
    .features-img-bg {
      position: relative;
      min-height: 500px;
      img {
        position: absolute;
        inset: 0;
        display: block;
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
      }
    }
    .image-stack {
      display: grid;
      position: relative;
      grid-template-columns: repeat(12, 1fr);
      .stack-back {
        grid-column: 4/-1;
        grid-row: 1;
        width: 100%;
        z-index: 1;
      }
      .stack-front {
        grid-row: 1;
        grid-column: 1 / span 8;
        margin-top: 20%;
        width: 100%;
        z-index: 2;
      }
    }
  }
  .features-item + .features-item {
    margin-top: 100px;
  }
  @media (max-width: 768px) {
    .features-item + .features-item {
      margin-top: 40px;
    }
  }
  @media (max-width: 640px) {
    .features-item {
      .features-img-bg {
        min-height: 300px;
      }
    }
  }
}

.home-area {
  width: 100%;
  // background: url("../../assets/images/header-bg.jpg") no-repeat scroll center
  //   bottom / cover;
  padding-top: 26vh;
  color: #ffffff;
  margin-bottom: 100px;

  h1,
  h2,
  h3,
  h4 {
    color: #ffffff;
  }
}

.home-area .mobile-image {
  margin-right: 0;
  margin-left: 0;
  margin-bottom: -158px;
}

.angle,
.overlay {
  position: relative;
  z-index: 1;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#482cbf+0,6ac6f0+100 */
    background: rgb(72, 44, 191);
    /* Old browsers */
    /* FF3.6-15 */
    background: -webkit-linear-gradient(
      45deg,
      rgba(72, 44, 191, 1) 0%,
      rgba(106, 198, 240, 1) 100%
    );
    /* Chrome10-25,Safari5.1-6 */
    background: -o-linear-gradient(
      45deg,
      rgba(72, 44, 191, 1) 0%,
      rgba(106, 198, 240, 1) 100%
    );
    background: linear-gradient(
      45deg,
      rgba(72, 44, 191, 1) 0%,
      rgba(106, 198, 240, 1) 100%
    );
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#482cbf', endColorstr='#6ac6f0', GradientType=1);
    opacity: 0.8;
    /* IE6-9 fallback on horizontal gradient */
    z-index: -2;
  }
}

$color_1: #444444;
$color_2: #37517e;
$color_3: #fff;

.footer-newsletter {
  padding: 50px 0;
  background: #f3f5fa;
  text-align: center;
  font-size: 15px;
  color: $color_1;
  h4 {
    font-size: 24px;
    margin: 0 0 20px 0;
    padding: 0;
    line-height: 1;
    font-weight: 600;
    color: $color_2;
  }
  form {
    margin-top: 30px;
    background: #fff;
    padding: 6px 10px;
    position: relative;
    border-radius: 50px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.06);
    text-align: left;
    input[type="email"] {
      border: 0;
      padding: 4px 8px;
      width: calc(100% - 100px);
    }
    input[type="submit"] {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      border: 0;
      background: none;
      font-size: 16px;
      padding: 0 20px;
      background: #47b2e4;
      color: $color_3;
      transition: 0.3s;
      border-radius: 50px;
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
      &:hover {
        background: #209dd8;
      }
    }
  }
}

.cta-section {
  background: linear-gradient(
    to right,
    rgb(39, 70, 133) 0%,
    rgb(61, 179, 197) 100%
  );
  color: #fff;

  h2 {
    color: #fff;
    font-size: 3rem;
    font-weight: 700;
  }

  /* .btn {
    background: #000000;
    color: #fff;

    border: none;
    padding: 15px 30px !important;
  }

  .cta-section .btn i {
    margin-right: 5px;
    font-size: 24px;
    line-height: 0;
  }
  .bx {
    font-family: boxicons !important;
    font-weight: 400;
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    text-rendering: auto;
    display: inline-block;
    text-transform: none;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }*/
}

.section {
  padding: 5rem 0;
  overflow: hidden;
}

.loved-companies {
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 0;
  display: flex;

  .with-us {
    width: 100px;
    color: #69748c;
    font-family: Euclid, sans-serif;
    font-size: 16px;
  }

  .div-block-73 {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  .logo-loved-client {
    max-height: 20px;
    max-width: 110px;
    opacity: 0.55;
    margin-left: 25px;
  }
  img {
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
    border: 0;
  }
}

.header-h2-v2 {
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 30px;
  display: flex;

  .left-h2-v2 {
    margin-top: 120px;
    margin-bottom: 0;
  }

  .heading-20 {
    max-width: 800px;
    color: #2c2e4a;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Euclid, sans-serif;
    font-size: 64px;
    font-weight: 600;
    line-height: 64px;
  }

  .p-under-h2-v2 {
    max-width: 660px;
    color: #69748c;
    font-family: Euclid, sans-serif;
    font-size: 24px;
    line-height: 30px;
  }

  .secondary-header-button-v2-copy {
    width: 170px;
    height: 170px;
    color: #69748c;
    background-color: #f5f7f9;
    border-radius: 50%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: -30px;
    margin-left: 0;
    font-size: 16px;
    display: flex;
    transform: rotate(-25deg);
  }

  .video-about-quizzes {
    width: 70px;
    margin-bottom: 7px;
  }
  img {
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
  }
  img {
    border: 0;
  }

  .text-block-161 {
    text-align: center;
    font-family: Euclid, sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;

    .span-circle-button-v2 {
      text-align: center;
      font-family: Times New Roman, TimesNewRoman, Times, Baskerville, Georgia,
        serif;
      font-size: 16px;
      font-style: italic;
      font-weight: 400;
      line-height: 16px;
    }
  }
}

.slide-scroll {
  background-color: #fff;
  border: 1.5px solid rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  margin-bottom: 70px;
  padding: 40px;
  will-change: transform, background;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  border-color: rgba(0, 0, 0, 0.024);
  background-color: rgb(247, 249, 250);

  .div-block-74 {
    justify-content: space-between;
    align-items: center;
    display: flex;

    .div-block-75 {
      width: 50%;
      margin-top: 0;

      .for-slider-number {
        border: 1.5px solid rgba(0, 0, 0, 0.1);
        border-radius: 50px;
        margin-top: 0;
        padding: 10px 20px;
        display: inline-block;
      }

      .h3-v2 {
        color: #2c2e4a;
        font-family: Euclid, sans-serif;
        font-size: 36px;
        font-weight: 400;
        line-height: 36px;
      }
      h3 {
        width: auto;
        color: #000;
        margin-top: 20px;
        margin-bottom: 10px;
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
      }

      .for-what-bullets {
        max-width: 100%;
        align-items: center;
        margin-top: 20px;
        display: flex;

        .grid-13,
        .grid-14 {
          grid-column-gap: 31px;
        }
        .w-layout-grid {
          grid-row-gap: 16px;
          grid-column-gap: 16px;
          grid-template-rows: auto auto;
          grid-template-columns: 1fr 1fr;
          grid-auto-columns: 1fr;
          display: grid;
        }

        .what-bullet {
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          display: flex;
        }
      }
    }

    .div-block-76 {
      max-width: 40%;
      position: relative;

      .image-153 {
        max-width: 100%;
        margin-top: 10px;
        margin-bottom: 30px;
      }
      img {
        max-width: 100%;
        vertical-align: middle;
        display: inline-block;
      }

      .image-155 {
        max-width: 210px;
        position: absolute;
        top: auto;
        bottom: -10%;
        left: auto;
        right: 0%;
        transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
          rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
        opacity: 1;
        transform-style: preserve-3d;
      }
    }
  }
}

$form-grid-gutter-widt: 1.5rem;

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -24px / 2;
  margin-left: -24px / 2;

  > .col,
  > [class*="col-"] {
    padding-right: 24px / 2;
    padding-left: 24px / 2;
  }
}
