/**
 * File: styles.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * Imports
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800,900&display=swap");
:root {
  --color-default: #212529;
  --color-default-rgb: 33, 37, 41;
  --color-background: #ffffff;
  --color-background-rgb: 255, 255, 255;
  --color-primary: #e84545;
  --color-primary-rgb: 232, 69, 69;
  --color-secondary: #32353a;
  --color-secondary-rgb: 50, 53, 58;
  --color-box-background: #ffffff;
  --color-box-background-rgb: 255, 255, 255;
  --color-inverse: #ffffff;
  --color-inverse-rgb: 255, 255, 255;
}

/*

	Margin / Padding Quick Resets

	example: top & bottom margin set to $spacing-unit
	.element {
		@include push--ends;
	}

	example: left & right padding set to $spacing-unit--small
	.element {
		@include soft--sides($spacing-unit--small);
	}

*/
/*

	Content margins

	fore removing first/last child margins

	example: default
	.element {
		@include content-margins;
	}

	output:
	.element > *:first-child {
		margin-top: 0;
	}
	.element > *:last-child {
		margin-bottom: 0;
	}

	example: empty selector
	.element {
		@include content-margins('false');
	}

	output:
	.element:first-child {
		margin-top: 0;
	}
	.element:last-child {
		margin-bottom: 0;
	}

*/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
:root {
  --color-default: #212529;
  --color-default-rgb: 33, 37, 41;
  --color-background: #ffffff;
  --color-background-rgb: 255, 255, 255;
  --color-primary: #e84545;
  --color-primary-rgb: 232, 69, 69;
  --color-secondary: #32353a;
  --color-secondary-rgb: 50, 53, 58;
  --color-box-background: #ffffff;
  --color-box-background-rgb: 255, 255, 255;
  --color-inverse: #ffffff;
  --color-inverse-rgb: 255, 255, 255;
}

/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/**
 * File: section31.scss
 *
 * Option:
 * Author:
 * License:
*/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
:root {
  --color-default: #212529;
  --color-default-rgb: 33, 37, 41;
  --color-background: #ffffff;
  --color-background-rgb: 255, 255, 255;
  --color-primary: #e84545;
  --color-primary-rgb: 232, 69, 69;
  --color-secondary: #32353a;
  --color-secondary-rgb: 50, 53, 58;
  --color-box-background: #ffffff;
  --color-box-background-rgb: 255, 255, 255;
  --color-inverse: #ffffff;
  --color-inverse-rgb: 255, 255, 255;
}

/*

	Margin / Padding Quick Resets

	example: top & bottom margin set to $spacing-unit
	.element {
		@include push--ends;
	}

	example: left & right padding set to $spacing-unit--small
	.element {
		@include soft--sides($spacing-unit--small);
	}

*/
/*

	Content margins

	fore removing first/last child margins

	example: default
	.element {
		@include content-margins;
	}

	output:
	.element > *:first-child {
		margin-top: 0;
	}
	.element > *:last-child {
		margin-bottom: 0;
	}

	example: empty selector
	.element {
		@include content-margins('false');
	}

	output:
	.element:first-child {
		margin-top: 0;
	}
	.element:last-child {
		margin-bottom: 0;
	}

*/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
:root {
  --color-default: #212529;
  --color-default-rgb: 33, 37, 41;
  --color-background: #ffffff;
  --color-background-rgb: 255, 255, 255;
  --color-primary: #e84545;
  --color-primary-rgb: 232, 69, 69;
  --color-secondary: #32353a;
  --color-secondary-rgb: 50, 53, 58;
  --color-box-background: #ffffff;
  --color-box-background-rgb: 255, 255, 255;
  --color-inverse: #ffffff;
  --color-inverse-rgb: 255, 255, 255;
}

/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/**
 * Variables
 **/
/**
 * Styles
 **/
/**
 * Extend element
 **/
.btn-get-started {
  color: #fff;
  background: var(--color-primary);
  text-transform: uppercase;
}

/**
 *	Style Modal Close Button
 **/
/**
 * Styles
 **/
.btn-new {
  display: inline-block;
  padding: 1.2rem 3rem;
  color: #fff;
  background: #482cbf;
  background: linear-gradient(45deg, rgb(72, 44, 191) 0%, rgb(106, 198, 240) 100%);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
  transform: translateY(0);
  border: none;
  border-radius: 50px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-weight: 700;
  font-size: 14px;
  transition: 0.3s;
}
.btn-new:focus, .btn-new:hover {
  text-decoration: none;
  outline: none;
}
.btn-new:hover {
  animation-delay: 0.8s;
  animation-name: fadeInUp;
  color: #fff;
  box-shadow: 0 25px 50px -20px rgba(221, 158, 158, 0.6);
  transform: translateY(-5px);
}

.btn-get-started {
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 1px;
  border-radius: 3px;
  margin: 10px;
}

.default-btn {
  font-size: 16px;
  color: #fff;
  padding: 15px 30px;
  line-height: 1;
  transition: all 0.5s;
  text-transform: capitalize;
  position: relative;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  background-color: #d80650;
}
.default-btn.active {
  margin-left: 20px;
  color: #0e0129;
  background-color: #fff;
}

/*===== All Button Style =====*/
.theme-btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 16px 48px;
  font-size: 18px;
  border-radius: 50px;
  color: #fff;
  cursor: pointer;
  z-index: 5;
  transition: all 0.4s ease-in-out;
  border: none;
  background: linear-gradient(to left, #3763eb 0%, #6f58e8 50.39%, #3763eb 100%);
  background-size: 200% auto;
  overflow: hidden;
}
.theme-btn:hover, .theme-btn:focus {
  background-position: right center;
  color: #fff;
  -webkit-box-shadow: 0px 0px 30px rgba(46, 87, 217, 0.45);
  -moz-box-shadow: 0px 0px 30px rgba(46, 87, 217, 0.45);
  box-shadow: 0px 0px 30px rgba(46, 87, 217, 0.45);
}
.theme-btn.border-btn {
  padding: 14px 40px;
  background: transparent;
  border: 1.5px solid #3763eb;
  color: #6a7c92;
  font-size: 18px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.scroll-top {
  width: 45px;
  height: 45px;
  background: #3763eb;
  text-align: center;
  line-height: 45px;
  font-size: 20px;
  color: #fff;
  border-radius: 5px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 9;
  cursor: pointer;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.scroll-top:hover {
  background: rgba(55, 99, 235, 0.7);
  color: #fff;
}

@keyframes animation1 {
  0% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 0.29;
  }
  50% {
    -webkit-transform: translateY(-700px);
    -moz-transform: translateY(-700px);
    -ms-transform: translateY(-700px);
    -o-transform: translateY(-700px);
    transform: translateY(-700px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 0;
  }
}
/**
 * File: Cookies.scss
 *
 * Option:
 * Author:
 * License:
*/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
:root {
  --color-default: #212529;
  --color-default-rgb: 33, 37, 41;
  --color-background: #ffffff;
  --color-background-rgb: 255, 255, 255;
  --color-primary: #e84545;
  --color-primary-rgb: 232, 69, 69;
  --color-secondary: #32353a;
  --color-secondary-rgb: 50, 53, 58;
  --color-box-background: #ffffff;
  --color-box-background-rgb: 255, 255, 255;
  --color-inverse: #ffffff;
  --color-inverse-rgb: 255, 255, 255;
}

/*

	Margin / Padding Quick Resets

	example: top & bottom margin set to $spacing-unit
	.element {
		@include push--ends;
	}

	example: left & right padding set to $spacing-unit--small
	.element {
		@include soft--sides($spacing-unit--small);
	}

*/
/*

	Content margins

	fore removing first/last child margins

	example: default
	.element {
		@include content-margins;
	}

	output:
	.element > *:first-child {
		margin-top: 0;
	}
	.element > *:last-child {
		margin-bottom: 0;
	}

	example: empty selector
	.element {
		@include content-margins('false');
	}

	output:
	.element:first-child {
		margin-top: 0;
	}
	.element:last-child {
		margin-bottom: 0;
	}

*/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
:root {
  --color-default: #212529;
  --color-default-rgb: 33, 37, 41;
  --color-background: #ffffff;
  --color-background-rgb: 255, 255, 255;
  --color-primary: #e84545;
  --color-primary-rgb: 232, 69, 69;
  --color-secondary: #32353a;
  --color-secondary-rgb: 50, 53, 58;
  --color-box-background: #ffffff;
  --color-box-background-rgb: 255, 255, 255;
  --color-inverse: #ffffff;
  --color-inverse-rgb: 255, 255, 255;
}

/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/**
 * File: mediaqueries.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
:root {
  --color-default: #212529;
  --color-default-rgb: 33, 37, 41;
  --color-background: #ffffff;
  --color-background-rgb: 255, 255, 255;
  --color-primary: #e84545;
  --color-primary-rgb: 232, 69, 69;
  --color-secondary: #32353a;
  --color-secondary-rgb: 50, 53, 58;
  --color-box-background: #ffffff;
  --color-box-background-rgb: 255, 255, 255;
  --color-inverse: #ffffff;
  --color-inverse-rgb: 255, 255, 255;
}

/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
:root {
  --color-default: #212529;
  --color-default-rgb: 33, 37, 41;
  --color-background: #ffffff;
  --color-background-rgb: 255, 255, 255;
  --color-primary: #e84545;
  --color-primary-rgb: 232, 69, 69;
  --color-secondary: #32353a;
  --color-secondary-rgb: 50, 53, 58;
  --color-box-background: #ffffff;
  --color-box-background-rgb: 255, 255, 255;
  --color-inverse: #ffffff;
  --color-inverse-rgb: 255, 255, 255;
}

/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/*
@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin for-size($size) {
  @if $size == phone-only {
    @media (max-width: 599px) {
      @content;
    }
  } @else if $size == tablet-portrait-up {
    @media (min-width: 600px) {
      @content;
    }
  } @else if $size == tablet-landscape-up {
    @media (min-width: 900px) {
      @content;
    }
  } @else if $size == desktop-up {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $size == big-desktop-up {
    @media (min-width: 1800px) {
      @content;
    }
  }
}
*/
/**
 * Variables
 **/
/**
 * Styles
 **/
.cookieconsent {
  position: fixed;
  bottom: 1rem !important;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  padding: 1.5rem;
  width: calc(100% - 5rem);
  margin: 0 auto;
  padding: 1.5rem;
  z-index: 10000;
  color: rgb(51, 50, 50);
  background-color: rgb(255, 255, 255);
  /*
   * Typography
   */
  font-size: 0.825rem;
}
.cookieconsent .btn-cookieconsent {
  padding: 0.825rem 1.65rem;
}
.cookieconsent .btn-cookieconsent {
  font-size: 1rem;
}

.cookieconsent-glass {
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);
}

/**
 * File: contact.scss
 *
 * Option:
 * Author:
 * License:
*/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
:root {
  --color-default: #212529;
  --color-default-rgb: 33, 37, 41;
  --color-background: #ffffff;
  --color-background-rgb: 255, 255, 255;
  --color-primary: #e84545;
  --color-primary-rgb: 232, 69, 69;
  --color-secondary: #32353a;
  --color-secondary-rgb: 50, 53, 58;
  --color-box-background: #ffffff;
  --color-box-background-rgb: 255, 255, 255;
  --color-inverse: #ffffff;
  --color-inverse-rgb: 255, 255, 255;
}

/*

	Margin / Padding Quick Resets

	example: top & bottom margin set to $spacing-unit
	.element {
		@include push--ends;
	}

	example: left & right padding set to $spacing-unit--small
	.element {
		@include soft--sides($spacing-unit--small);
	}

*/
/*

	Content margins

	fore removing first/last child margins

	example: default
	.element {
		@include content-margins;
	}

	output:
	.element > *:first-child {
		margin-top: 0;
	}
	.element > *:last-child {
		margin-bottom: 0;
	}

	example: empty selector
	.element {
		@include content-margins('false');
	}

	output:
	.element:first-child {
		margin-top: 0;
	}
	.element:last-child {
		margin-bottom: 0;
	}

*/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
:root {
  --color-default: #212529;
  --color-default-rgb: 33, 37, 41;
  --color-background: #ffffff;
  --color-background-rgb: 255, 255, 255;
  --color-primary: #e84545;
  --color-primary-rgb: 232, 69, 69;
  --color-secondary: #32353a;
  --color-secondary-rgb: 50, 53, 58;
  --color-box-background: #ffffff;
  --color-box-background-rgb: 255, 255, 255;
  --color-inverse: #ffffff;
  --color-inverse-rgb: 255, 255, 255;
}

/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/**
 * Variables
 **/
/**
 * Styles
 **/
/**
 * Extend element
 **/
/**
 * File: contact.scss
 *
 * Option:
 * Author:
 * License:
*/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
:root {
  --color-default: #212529;
  --color-default-rgb: 33, 37, 41;
  --color-background: #ffffff;
  --color-background-rgb: 255, 255, 255;
  --color-primary: #e84545;
  --color-primary-rgb: 232, 69, 69;
  --color-secondary: #32353a;
  --color-secondary-rgb: 50, 53, 58;
  --color-box-background: #ffffff;
  --color-box-background-rgb: 255, 255, 255;
  --color-inverse: #ffffff;
  --color-inverse-rgb: 255, 255, 255;
}

/*

	Margin / Padding Quick Resets

	example: top & bottom margin set to $spacing-unit
	.element {
		@include push--ends;
	}

	example: left & right padding set to $spacing-unit--small
	.element {
		@include soft--sides($spacing-unit--small);
	}

*/
/*

	Content margins

	fore removing first/last child margins

	example: default
	.element {
		@include content-margins;
	}

	output:
	.element > *:first-child {
		margin-top: 0;
	}
	.element > *:last-child {
		margin-bottom: 0;
	}

	example: empty selector
	.element {
		@include content-margins('false');
	}

	output:
	.element:first-child {
		margin-top: 0;
	}
	.element:last-child {
		margin-bottom: 0;
	}

*/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
:root {
  --color-default: #212529;
  --color-default-rgb: 33, 37, 41;
  --color-background: #ffffff;
  --color-background-rgb: 255, 255, 255;
  --color-primary: #e84545;
  --color-primary-rgb: 232, 69, 69;
  --color-secondary: #32353a;
  --color-secondary-rgb: 50, 53, 58;
  --color-box-background: #ffffff;
  --color-box-background-rgb: 255, 255, 255;
  --color-inverse: #ffffff;
  --color-inverse-rgb: 255, 255, 255;
}

/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/**
 * File: mediaqueries.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
:root {
  --color-default: #212529;
  --color-default-rgb: 33, 37, 41;
  --color-background: #ffffff;
  --color-background-rgb: 255, 255, 255;
  --color-primary: #e84545;
  --color-primary-rgb: 232, 69, 69;
  --color-secondary: #32353a;
  --color-secondary-rgb: 50, 53, 58;
  --color-box-background: #ffffff;
  --color-box-background-rgb: 255, 255, 255;
  --color-inverse: #ffffff;
  --color-inverse-rgb: 255, 255, 255;
}

/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
:root {
  --color-default: #212529;
  --color-default-rgb: 33, 37, 41;
  --color-background: #ffffff;
  --color-background-rgb: 255, 255, 255;
  --color-primary: #e84545;
  --color-primary-rgb: 232, 69, 69;
  --color-secondary: #32353a;
  --color-secondary-rgb: 50, 53, 58;
  --color-box-background: #ffffff;
  --color-box-background-rgb: 255, 255, 255;
  --color-inverse: #ffffff;
  --color-inverse-rgb: 255, 255, 255;
}

/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/*
@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin for-size($size) {
  @if $size == phone-only {
    @media (max-width: 599px) {
      @content;
    }
  } @else if $size == tablet-portrait-up {
    @media (min-width: 600px) {
      @content;
    }
  } @else if $size == tablet-landscape-up {
    @media (min-width: 900px) {
      @content;
    }
  } @else if $size == desktop-up {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $size == big-desktop-up {
    @media (min-width: 1800px) {
      @content;
    }
  }
}
*/
/**
 * Variables
 **/
/**
 * Styles
 **/
.top-menu .top-menu-phone-nav a, .top-menu-navbar .top-menu-navbar-nav .nav-link,
.top-menu-navbar .footer-menu-navbar-nav .nav-link,
.footer-menu-navbar .top-menu-navbar-nav .nav-link,
.footer-menu-navbar .footer-menu-navbar-nav .nav-link {
  position: relative;
  color: #f9f9f9;
  font-weight: 400;
  font-size: 1rem;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  outline: none;
}

.top-menu-navbar {
  padding: 0;
}
.top-menu-navbar .navbar-brand {
  padding: 0;
  font-weight: 400;
  text-decoration: none;
  text-transform: uppercase;
}
.top-menu-navbar .top-menu-navbar-icon {
  margin-right: 0.25rem;
}
.top-menu-navbar .top-menu-navbar-nav .dropdown-menu {
  padding: 0.5rem 1rem;
  margin-top: 0.825rem;
  background: rgba(0, 0, 0, 0.8);
}
.top-menu-navbar .top-menu-navbar-nav .dropdown-menu .nav-link {
  margin: 0;
  background-color: transparent;
  text-align: left;
}

.footer-menu-navbar-nav {
  justify-content: center;
}
.footer-menu-navbar-nav .nav-link:not(:first-child) {
  margin-left: 1rem;
}

.top-menu-navbar-nav .nav-link {
  margin-left: 1rem;
}

.top-menu-navbar .top-menu-navbar-nav .nav-link,
.top-menu-navbar .footer-menu-navbar-nav .nav-link,
.footer-menu-navbar .top-menu-navbar-nav .nav-link,
.footer-menu-navbar .footer-menu-navbar-nav .nav-link {
  display: inline-block;
}
.top-menu-navbar .top-menu-navbar-nav .nav-link:before,
.top-menu-navbar .footer-menu-navbar-nav .nav-link:before,
.footer-menu-navbar .top-menu-navbar-nav .nav-link:before,
.footer-menu-navbar .footer-menu-navbar-nav .nav-link:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #5584ff;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}
.top-menu-navbar .top-menu-navbar-nav .nav-link:focus,
.top-menu-navbar .footer-menu-navbar-nav .nav-link:focus,
.footer-menu-navbar .top-menu-navbar-nav .nav-link:focus,
.footer-menu-navbar .footer-menu-navbar-nav .nav-link:focus {
  color: #fafafa;
}
.top-menu-navbar .top-menu-navbar-nav .nav-link:hover,
.top-menu-navbar .footer-menu-navbar-nav .nav-link:hover,
.footer-menu-navbar .top-menu-navbar-nav .nav-link:hover,
.footer-menu-navbar .footer-menu-navbar-nav .nav-link:hover {
  color: #fafafa;
}
.top-menu-navbar .top-menu-navbar-nav .nav-link:hover:before,
.top-menu-navbar .footer-menu-navbar-nav .nav-link:hover:before,
.footer-menu-navbar .top-menu-navbar-nav .nav-link:hover:before,
.footer-menu-navbar .footer-menu-navbar-nav .nav-link:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

.top-menu {
  padding: 0.8rem 0;
  background: rgba(0, 0, 0, 0.8);
}
.top-menu.scrolled {
  background: rgba(0, 0, 0, 0.8);
}
.top-menu .top-menu-phone-nav {
  margin-left: auto;
}
.top-menu .top-menu-phone-nav a {
  padding: 0.5rem 1rem;
  display: inline-block;
}
.top-menu .top-menu-phone-nav a:hover {
  color: var(--color-primary);
  transition: 0.3s;
}
.top-menu .top-menu-phone-nav a svg {
  margin-right: 0.5rem;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
}

/*
.top-menu-nav a,
.top-menu-nav a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  white-space: nowrap;
  transition: 0.3s;
}

.top-menu99 {
  padding: 0 0 14px 0;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  transition: all 0.5s;
  z-index: 997;

  background: rgba(55, 64, 85, 0.9);
  padding: 15px 0;

  &.header-scrolled {
    background: rgba(0, 0, 0, 0.8);
    transition: all 0.5s;
  }
}
*/
.main-page-notfound {
  position: relative;
  height: 100vh;
  background-color: #222;
}
.main-page-notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.notfound {
  max-width: 460px;
  width: 100%;
  text-align: center;
  line-height: 1.4;
}
.notfound .notfound-404 {
  display: block;
  margin-bottom: 2rem;
  line-height: 153px;
}
.notfound .notfound-404 h1 {
  color: #222;
  font-size: 220px;
  letter-spacing: 10px;
  margin: 0;
  font-weight: 700;
  text-shadow: 2px 2px 0 #c9c9c9, -2px -2px 0 #c9c9c9;
}
.notfound .notfound-404 h1 > span {
  text-shadow: 2px 2px 0 #ffab00, -2px -2px 0 #ffab00, 0 0 8px #ff8700;
}
.notfound h2,
.notfound h3 {
  color: #c9c9c9;
}
.notfound p {
  color: #c9c9c9;
  font-size: 1rem;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 1rem;
}
.notfound a {
  font-size: 0.8rem;
  text-decoration: none;
  text-transform: uppercase;
  background: 0 0;
  color: #c9c9c9;
  border: 2px solid #c9c9c9;
  display: inline-block;
  padding: 10px 25px;
  font-weight: 700;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}
.notfound a:hover {
  color: #ffab00;
  border-color: #ffab00;
}

@media only screen and (max-width: 480px) {
  .notfound .notfound-404 {
    height: 122px;
    line-height: 122px;
  }
  .notfound .notfound-404 h1 {
    font-size: 122px;
  }
}
.section-offer-contract-policy .container, .section-privacy-policy .container {
  background: #fff;
  border-radius: 2px;
  padding: 45px;
  margin: 0 auto;
}

/**
 *	Typography Style
 **/
.offer-contract-policy ol {
  list-style: none;
  counter-reset: li;
  padding-left: 1rem;
  text-align: justify;
}
.offer-contract-policy ol > li:before {
  counter-increment: li;
  content: counters(li, ".") ". ";
}
.offer-contract-policy ol ul {
  list-style-type: disc;
  margin: 0;
}
.offer-contract-policy ol li,
.offer-contract-policy ul li {
  padding: 1.25rem 0 0;
  font-size: 1rem;
  line-height: 1.75rem;
  letter-spacing: -0.03125rem;
}
.offer-contract-policy ol li ol,
.offer-contract-policy ol li ul,
.offer-contract-policy ul li ol,
.offer-contract-policy ul li ul {
  padding-top: 0.3125rem;
}
.offer-contract-policy ol li ol li,
.offer-contract-policy ol li ul li,
.offer-contract-policy ul li ol li,
.offer-contract-policy ul li ul li {
  padding: 0;
  line-height: 1.5rem;
  letter-spacing: -0.03125rem;
}

/**
 * File: footer.scss
 *
 * Option:
 * Author:
 * License:
*/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
:root {
  --color-default: #212529;
  --color-default-rgb: 33, 37, 41;
  --color-background: #ffffff;
  --color-background-rgb: 255, 255, 255;
  --color-primary: #e84545;
  --color-primary-rgb: 232, 69, 69;
  --color-secondary: #32353a;
  --color-secondary-rgb: 50, 53, 58;
  --color-box-background: #ffffff;
  --color-box-background-rgb: 255, 255, 255;
  --color-inverse: #ffffff;
  --color-inverse-rgb: 255, 255, 255;
}

/*

	Margin / Padding Quick Resets

	example: top & bottom margin set to $spacing-unit
	.element {
		@include push--ends;
	}

	example: left & right padding set to $spacing-unit--small
	.element {
		@include soft--sides($spacing-unit--small);
	}

*/
/*

	Content margins

	fore removing first/last child margins

	example: default
	.element {
		@include content-margins;
	}

	output:
	.element > *:first-child {
		margin-top: 0;
	}
	.element > *:last-child {
		margin-bottom: 0;
	}

	example: empty selector
	.element {
		@include content-margins('false');
	}

	output:
	.element:first-child {
		margin-top: 0;
	}
	.element:last-child {
		margin-bottom: 0;
	}

*/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
:root {
  --color-default: #212529;
  --color-default-rgb: 33, 37, 41;
  --color-background: #ffffff;
  --color-background-rgb: 255, 255, 255;
  --color-primary: #e84545;
  --color-primary-rgb: 232, 69, 69;
  --color-secondary: #32353a;
  --color-secondary-rgb: 50, 53, 58;
  --color-box-background: #ffffff;
  --color-box-background-rgb: 255, 255, 255;
  --color-inverse: #ffffff;
  --color-inverse-rgb: 255, 255, 255;
}

/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/**
 * File: mediaqueries.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
:root {
  --color-default: #212529;
  --color-default-rgb: 33, 37, 41;
  --color-background: #ffffff;
  --color-background-rgb: 255, 255, 255;
  --color-primary: #e84545;
  --color-primary-rgb: 232, 69, 69;
  --color-secondary: #32353a;
  --color-secondary-rgb: 50, 53, 58;
  --color-box-background: #ffffff;
  --color-box-background-rgb: 255, 255, 255;
  --color-inverse: #ffffff;
  --color-inverse-rgb: 255, 255, 255;
}

/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
:root {
  --color-default: #212529;
  --color-default-rgb: 33, 37, 41;
  --color-background: #ffffff;
  --color-background-rgb: 255, 255, 255;
  --color-primary: #e84545;
  --color-primary-rgb: 232, 69, 69;
  --color-secondary: #32353a;
  --color-secondary-rgb: 50, 53, 58;
  --color-box-background: #ffffff;
  --color-box-background-rgb: 255, 255, 255;
  --color-inverse: #ffffff;
  --color-inverse-rgb: 255, 255, 255;
}

/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/*
@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin for-size($size) {
  @if $size == phone-only {
    @media (max-width: 599px) {
      @content;
    }
  } @else if $size == tablet-portrait-up {
    @media (min-width: 600px) {
      @content;
    }
  } @else if $size == tablet-landscape-up {
    @media (min-width: 900px) {
      @content;
    }
  } @else if $size == desktop-up {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $size == big-desktop-up {
    @media (min-width: 1800px) {
      @content;
    }
  }
}
*/
/**
 * Variables
 **/
/**
 * Styles
 **/
.main-footer {
  color: white;
  background-color: #222;
}
.main-footer .footer-top {
  padding: 4.5rem 0 3rem 0;
  text-align: center;
}
.main-footer .footer-text {
  color: white;
}
.main-footer .footer-bottom {
  padding-top: 40px;
  padding-bottom: 40px;
}
.main-footer .copyright {
  text-align: center;
}
.main-footer .credits {
  text-align: center;
  font-size: 13px;
  padding-top: 5px;
}

/*===========================
    01. Common CSS
===========================*/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
:root {
  --color-default: #212529;
  --color-default-rgb: 33, 37, 41;
  --color-background: #ffffff;
  --color-background-rgb: 255, 255, 255;
  --color-primary: #e84545;
  --color-primary-rgb: 232, 69, 69;
  --color-secondary: #32353a;
  --color-secondary-rgb: 50, 53, 58;
  --color-box-background: #ffffff;
  --color-box-background-rgb: 255, 255, 255;
  --color-inverse: #ffffff;
  --color-inverse-rgb: 255, 255, 255;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #6a7c92;
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.navbar-toggler:focus,
a:focus,
input:focus,
textarea:focus,
button:focus,
.btn:focus,
.btn.focus,
.btn:not(:disabled):not(.disabled).active,
.btn:not(:disabled):not(.disabled):active {
  text-decoration: none;
  outline: none;
}

a:hover {
  transition: 0.3s;
  color: #3763eb;
}
@media (prefers-reduced-motion: reduce) {
  a:hover {
    transition: none;
  }
}

a:focus,
a:hover {
  text-decoration: none;
}

button {
  cursor: pointer;
}

i,
span,
a {
  display: inline-block;
  text-decoration: none;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  color: #051441;
  margin: 0px;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

h1 {
  font-size: 50px;
}

h2 {
  font-size: 40px;
}
@media (max-width: 767px) {
  h2 {
    font-size: 31px;
  }
}

h3 {
  font-size: 30px;
}

h4 {
  font-size: 25px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 16px;
}

ul,
ol {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

p {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #6a7c92;
  margin: 0px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  p {
    font-size: 16px;
    line-height: 26px;
  }
}
@media (max-width: 767px) {
  p {
    font-size: 16px;
    line-height: 26px;
  }
}

.img-bg {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .container {
    width: 450px;
  }
}

.gray-bg {
  background: #eff2f9;
}

.alert-link {
  font-weight: 600;
}

.alert h4 {
  margin-bottom: 10px;
}

.page-404-content h2 {
  font-size: 120px;
  font-weight: 900;
  color: #3763eb;
}
.page-404-content h4 {
  font-size: 40px;
  color: #6a7c92;
}

/*===== All Section Title Style =====*/
.section-title span {
  font-size: 25px;
  font-weight: 700;
  color: #3763eb;
  margin-bottom: 5px;
}
@media (max-width: 767px) {
  .section-title span {
    font-size: 20px;
  }
}
.section-title h2 {
  line-height: 55px;
  font-weight: 600;
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .section-title h2 {
    line-height: 41px;
  }
}
.section-title p {
  font-size: 18px;
}

.section-title {
  text-align: center;
}
.section-title h2 {
  color: var(--color-secondary);
  font-size: 32px;
  font-weight: 700;
  position: relative;
}
.section-title h2:before {
  content: "";
  width: 50px;
  height: 2px;
  background: var(--color-primary);
  display: inline-block;
  margin: 0 15px 10px 0;
}
.section-title h2:after {
  content: "";
  width: 50px;
  height: 2px;
  background: var(--color-primary);
  display: inline-block;
  margin: 0 0 10px 15px;
}
.section-title p {
  margin-bottom: 0;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-85 {
  margin-top: 85px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-95 {
  margin-top: 95px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-105 {
  margin-top: 105px;
}

.mt-110 {
  margin-top: 110px;
}

.mt-115 {
  margin-top: 115px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-125 {
  margin-top: 125px;
}

.mt-130 {
  margin-top: 130px;
}

.mt-135 {
  margin-top: 135px;
}

.mt-140 {
  margin-top: 140px;
}

.mt-145 {
  margin-top: 145px;
}

.mt-150 {
  margin-top: 150px;
}

.mt-155 {
  margin-top: 155px;
}

.mt-160 {
  margin-top: 160px;
}

.mt-165 {
  margin-top: 165px;
}

.mt-170 {
  margin-top: 170px;
}

.mt-175 {
  margin-top: 175px;
}

.mt-180 {
  margin-top: 180px;
}

.mt-185 {
  margin-top: 185px;
}

.mt-190 {
  margin-top: 190px;
}

.mt-195 {
  margin-top: 195px;
}

.mt-200 {
  margin-top: 200px;
}

.mt-205 {
  margin-top: 205px;
}

.mt-210 {
  margin-top: 210px;
}

.mt-215 {
  margin-top: 215px;
}

.mt-220 {
  margin-top: 220px;
}

.mt-225 {
  margin-top: 225px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-85 {
  margin-bottom: 85px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-105 {
  margin-bottom: 105px;
}

.mb-110 {
  margin-bottom: 110px;
}

.mb-115 {
  margin-bottom: 115px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mb-125 {
  margin-bottom: 125px;
}

.mb-130 {
  margin-bottom: 130px;
}

.mb-135 {
  margin-bottom: 135px;
}

.mb-140 {
  margin-bottom: 140px;
}

.mb-145 {
  margin-bottom: 145px;
}

.mb-150 {
  margin-bottom: 150px;
}

.mb-155 {
  margin-bottom: 155px;
}

.mb-160 {
  margin-bottom: 160px;
}

.mb-165 {
  margin-bottom: 165px;
}

.mb-170 {
  margin-bottom: 170px;
}

.mb-175 {
  margin-bottom: 175px;
}

.mb-180 {
  margin-bottom: 180px;
}

.mb-185 {
  margin-bottom: 185px;
}

.mb-190 {
  margin-bottom: 190px;
}

.mb-195 {
  margin-bottom: 195px;
}

.mb-200 {
  margin-bottom: 200px;
}

.mb-205 {
  margin-bottom: 205px;
}

.mb-210 {
  margin-bottom: 210px;
}

.mb-215 {
  margin-bottom: 215px;
}

.mb-220 {
  margin-bottom: 220px;
}

.mb-225 {
  margin-bottom: 225px;
}

.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-55 {
  padding-top: 55px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-65 {
  padding-top: 65px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-85 {
  padding-top: 85px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-95 {
  padding-top: 95px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-105 {
  padding-top: 105px;
}

.pt-110 {
  padding-top: 110px;
}

.pt-115 {
  padding-top: 115px;
}

.pt-120 {
  padding-top: 120px;
}

.pt-125 {
  padding-top: 125px;
}

.pt-130 {
  padding-top: 130px;
}

.pt-135 {
  padding-top: 135px;
}

.pt-140 {
  padding-top: 140px;
}

.pt-145 {
  padding-top: 145px;
}

.pt-150 {
  padding-top: 150px;
}

.pt-155 {
  padding-top: 155px;
}

.pt-160 {
  padding-top: 160px;
}

.pt-165 {
  padding-top: 165px;
}

.pt-170 {
  padding-top: 170px;
}

.pt-175 {
  padding-top: 175px;
}

.pt-180 {
  padding-top: 180px;
}

.pt-185 {
  padding-top: 185px;
}

.pt-190 {
  padding-top: 190px;
}

.pt-195 {
  padding-top: 195px;
}

.pt-200 {
  padding-top: 200px;
}

.pt-205 {
  padding-top: 205px;
}

.pt-210 {
  padding-top: 210px;
}

.pt-215 {
  padding-top: 215px;
}

.pt-220 {
  padding-top: 220px;
}

.pt-225 {
  padding-top: 225px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-105 {
  padding-bottom: 105px;
}

.pb-110 {
  padding-bottom: 110px;
}

.pb-115 {
  padding-bottom: 115px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pb-125 {
  padding-bottom: 125px;
}

.pb-130 {
  padding-bottom: 130px;
}

.pb-135 {
  padding-bottom: 135px;
}

.pb-140 {
  padding-bottom: 140px;
}

.pb-145 {
  padding-bottom: 145px;
}

.pb-150 {
  padding-bottom: 150px;
}

.pb-155 {
  padding-bottom: 155px;
}

.pb-160 {
  padding-bottom: 160px;
}

.pb-165 {
  padding-bottom: 165px;
}

.pb-170 {
  padding-bottom: 170px;
}

.pb-175 {
  padding-bottom: 175px;
}

.pb-180 {
  padding-bottom: 180px;
}

.pb-185 {
  padding-bottom: 185px;
}

.pb-190 {
  padding-bottom: 190px;
}

.pb-195 {
  padding-bottom: 195px;
}

.pb-200 {
  padding-bottom: 200px;
}

.pb-205 {
  padding-bottom: 205px;
}

.pb-210 {
  padding-bottom: 210px;
}

.pb-215 {
  padding-bottom: 215px;
}

.pb-220 {
  padding-bottom: 220px;
}

.pb-225 {
  padding-bottom: 225px;
}

.ourteam {
  --color-background: #f4f4f4;
  --color-box-background-rgb: 0, 0, 0;
}
.ourteam .member {
  position: relative;
}
.ourteam .member .member-img {
  margin: 0 80px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  border: 4px solid var(--color-box-background);
  box-shadow: 0 15px 35px -10px rgba(0, 0, 0, 0.2);
}
.ourteam .member .member-img img {
  position: relative;
  z-index: 1;
}
.ourteam .member .member-img .social {
  position: absolute;
  inset: 0;
  background-color: rgba(var(--color-box-background-rgb), 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  padding-bottom: 20px;
  transition: 0.3s;
  visibility: hidden;
  opacity: 0;
}
.ourteam .member .member-img .social a {
  transition: 0.3s;
  color: var(--color-inverse);
  font-size: 20px;
  margin: 0 8px;
}
.ourteam .member .member-img .social a:hover {
  color: var(--color-primary);
}
.ourteam .member .member-info {
  margin-top: 30px;
}
.ourteam .member .member-info h4 {
  color: var(--color-secondary);
  font-weight: 700;
  margin-bottom: 6px;
  font-size: 18px;
}
.ourteam .member .member-info span {
  font-style: italic;
  display: block;
  font-size: 15px;
  color: rgba(var(--color-default-rgb), 0.6);
  margin-bottom: 10px;
}
.ourteam .member .member-info p {
  margin-bottom: 0;
  font-size: 14px;
}
.ourteam .member:hover .member-img .social {
  padding-bottom: 0;
  visibility: visible;
  opacity: 1;
}
@media (max-width: 1024px) {
  .ourteam .member .member-img {
    margin: 0 60px;
  }
}

.faq1 .content h3 {
  color: var(--color-secondary);
  font-weight: 400;
  font-size: 34px;
}
.faq1 .content p {
  font-size: 15px;
  color: rgba(var(--color-default-rgb), 0.7);
}
.faq1 .faq-container .faq-item {
  position: relative;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 5px 25px 0px rgba(var(--color-default-rgb), 0.1);
  overflow: hidden;
}
.faq1 .faq-container .faq-item:last-child {
  margin-bottom: 0;
}
.faq1 .faq-container .faq-item h3 {
  color: var(--color-secondary);
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin: 0 30px 0 0;
  transition: 0.3s;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
}
.faq1 .faq-container .faq-item h3 .num {
  color: var(--color-primary);
  padding-right: 5px;
}
.faq1 .faq-container .faq-item h3:hover {
  color: var(--color-primary);
}
.faq1 .faq-container .faq-item .faq-content {
  display: grid;
  grid-template-rows: 0fr;
  transition: 0.3s ease-in-out;
}
.faq1 .faq-container .faq-item .faq-content p {
  margin-bottom: 0;
}
.faq1 .faq-container .faq-active h3 {
  color: var(--color-primary);
}
.faq1 .faq-container .faq-active .faq-content {
  grid-template-rows: 1fr;
  visibility: visible;
  opacity: 1;
  padding-top: 10px;
}
.faq1 .faq-container .faq-active .faq-toggle {
  transform: rotate(90deg);
  color: var(--color-primary);
}

.service-section .service-box-style-2 {
  padding: 0;
  text-align: center;
}
.service-section .service-box-style-2 .service-thumbnail img {
  width: 100%;
  border-radius: 10px 10px 0 0;
}
.service-section .service-box-style-2 .box-icon-style {
  margin-left: auto;
  margin-right: auto;
  width: 54px;
  height: 54px;
  border: 2px solid #fff;
  font-size: 25px;
  margin-top: -27px;
  background: #fff;
  position: relative;
  z-index: 9;
}
.service-section .service-box-style-2 .box-icon-style i {
  color: transparent;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -ms-background-clip: text;
  -o-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(#3763eb 0%, #6f58e8 100%);
  transition: 0.3s;
}
@media (prefers-reduced-motion: reduce) {
  .service-section .service-box-style-2 .box-icon-style i {
    transition: none;
  }
}
.service-section .service-box-style-2:hover .box-icon-style {
  background: linear-gradient(#3763eb 0%, #6f58e8 100%);
}
.service-section .service-box-style-2:hover .box-icon-style i {
  color: #fff;
}
.service-section .service-box-style-2 .box-content-style {
  padding: 0 30px 35px;
}

.box-style {
  padding: 40px 30px;
  border: 1px solid #f4eefb;
  border-radius: 10px;
  margin-bottom: 30px;
  transition: 0.3s;
}
@media (prefers-reduced-motion: reduce) {
  .box-style {
    transition: none;
  }
}
.box-style:hover .box-icon-style {
  color: #fff;
  border-color: transparent;
  background: linear-gradient(#3763eb 0%, #6f58e8 100%);
}
.box-style:hover .box-icon-style::after {
  opacity: 1;
  visibility: visible;
}
.box-style .box-icon-style {
  width: 78px;
  height: 78px;
  border-radius: 50%;
  margin-bottom: 25px;
  background: #fff;
  border: 2px solid #3763eb;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3763eb;
  font-size: 40px;
  transition: 0.3s;
  position: relative;
}
@media (prefers-reduced-motion: reduce) {
  .box-style .box-icon-style {
    transition: none;
  }
}
.box-style .box-icon-style::after {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  background: linear-gradient(#3763eb 0%, #6f58e8 100%);
  transition: 0.3s;
  z-index: -1;
  border-radius: 50%;
  border: 1px solid transparent;
}
@media (prefers-reduced-motion: reduce) {
  .box-style .box-icon-style::after {
    transition: none;
  }
}
.box-style .box-content-style h4 {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 20px;
}

.testimonial .testimonial-item .testimonial-content {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.process-section .process-img-wrapper {
  position: relative;
  z-index: 1;
}
.process-section .process-img-wrapper::before {
  content: "";
  position: absolute;
  left: -100px;
  bottom: -50px;
  width: 463px;
  height: 552px;
  border-radius: 10px 10px 231px 231px;
  background: linear-gradient(#f37da7 0%, #fed9b4 100%);
  opacity: 0.1;
}
.process-section .process-img-wrapper .process-img-top {
  margin-bottom: 20px;
  text-align: right;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .process-section .process-img-wrapper .process-img-top {
    text-align: center;
  }
}
.process-section .process-img-wrapper .process-img-top .img-1 {
  border-radius: 275px 275px 0 0;
}
@media (max-width: 767px) {
  .process-section .process-img-wrapper .process-img-top .img-1 {
    width: 100%;
  }
}
.process-section .process-img-wrapper .process-img-bottom {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .process-section .process-img-wrapper .process-img-bottom {
    justify-content: center;
  }
}
.process-section .process-img-wrapper .process-img-bottom .img-2 {
  border-radius: 10px;
  margin-right: 20px;
  vertical-align: top;
}
.process-section .process-img-wrapper .process-img-bottom .img-3 {
  border-radius: 0 0 250px 250px;
}

.time-line {
  position: relative;
}
.time-line .row {
  position: relative;
}
.time-line::after {
  content: "";
  position: absolute;
  width: 2px;
  height: 100%;
  left: 50%;
  top: 0;
  background: #f4eefb;
  z-index: -1;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .time-line::after {
    display: none;
  }
}
@media (max-width: 767px) {
  .time-line::after {
    display: none;
  }
}
.time-line .box-icon-style {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #f4eefb;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 2;
  background-color: #fff;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: 0.3s;
}
@media (prefers-reduced-motion: reduce) {
  .time-line .box-icon-style {
    transition: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .time-line .box-icon-style {
    position: static;
    -webkit-transform: translate(0);
    -moz-transform: translate(0);
    -ms-transform: translate(0);
    -o-transform: translate(0);
    transform: translate(0);
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .time-line .box-icon-style {
    position: static;
    -webkit-transform: translate(0);
    -moz-transform: translate(0);
    -ms-transform: translate(0);
    -o-transform: translate(0);
    transform: translate(0);
    margin-bottom: 30px;
  }
}
.time-line .box-icon-style i {
  transition: 0.3s;
  font-size: 20px;
  padding: 2px;
  color: #3763eb;
}
@media (prefers-reduced-motion: reduce) {
  .time-line .box-icon-style i {
    transition: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .time-line .single-timeline {
    margin-bottom: 50px;
  }
}
@media (max-width: 767px) {
  .time-line .single-timeline {
    margin-bottom: 50px;
  }
}
.time-line .single-timeline:hover .box-icon-style {
  background: linear-gradient(#3763eb 0%, #6f58e8 100%);
  color: #fff;
  border-color: transparent;
}
.time-line .single-timeline:hover .box-icon-style i {
  color: #fff;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .time-line .single-timeline .timeline-img {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .time-line .single-timeline .timeline-img {
    margin-bottom: 30px;
  }
}
.time-line .single-timeline .timeline-img img {
  width: 100%;
}

.contact-section {
  position: relative;
  z-index: 3;
}
.contact-section.cta-bg {
  clip-path: polygon(0 0, 100% 12%, 100% 88%, 0 100%);
  z-index: 3;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-section.cta-bg {
    clip-path: polygon(0 3%, 100% 12%, 100% 88%, 0 97%);
  }
}
@media (max-width: 767px) {
  .contact-section.cta-bg {
    clip-path: polygon(0 3%, 100% 12%, 100% 88%, 0 97%);
  }
}
.contact-section.cta-bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: linear-gradient(to left, rgba(111, 88, 232, 0.18) 0%, #3763eb 100%);
}
.contact-section .contact-item-wrapper .contact-item {
  display: flex;
  border: 1px solid #f4eefb;
  border-radius: 10px;
  background: #fff;
  margin-bottom: 30px;
  padding: 20px 30px;
  transition: 0.3s;
}
@media (prefers-reduced-motion: reduce) {
  .contact-section .contact-item-wrapper .contact-item {
    transition: none;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .contact-section .contact-item-wrapper .contact-item {
    padding: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-section .contact-item-wrapper .contact-item {
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .contact-section .contact-item-wrapper .contact-item {
    flex-direction: column;
  }
}
.contact-section .contact-item-wrapper .contact-item .contact-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: linear-gradient(#3763eb 0%, #6f58e8 100%);
  color: #fff;
  transition: 0.3s;
}
@media (prefers-reduced-motion: reduce) {
  .contact-section .contact-item-wrapper .contact-item .contact-icon {
    transition: none;
  }
}
.contact-section .contact-item-wrapper .contact-item .contact-content {
  margin-left: 25px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .contact-section .contact-item-wrapper .contact-item .contact-content {
    margin-left: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-section .contact-item-wrapper .contact-item .contact-content {
    margin-left: 0px;
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  .contact-section .contact-item-wrapper .contact-item .contact-content {
    margin-left: 0px;
    margin-top: 20px;
  }
}
.contact-section .contact-item-wrapper .contact-item .contact-content h4 {
  font-size: 20px;
  color: #3763eb;
  margin-bottom: 10px;
}

.comment-form-wrapper,
.contact-form-wrapper {
  padding: 50px 40px;
  background: #fff;
  border: 1px solid #f4eefb;
  margin-left: 0px;
  border-radius: 10px;
  transition: 0.3s;
}
@media (prefers-reduced-motion: reduce) {
  .comment-form-wrapper,
  .contact-form-wrapper {
    transition: none;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .comment-form-wrapper,
  .contact-form-wrapper {
    margin-left: 30px;
  }
}
.comment-form-wrapper .comment-form input,
.comment-form-wrapper .comment-form textarea,
.comment-form-wrapper .contact-form input,
.comment-form-wrapper .contact-form textarea,
.contact-form-wrapper .comment-form input,
.contact-form-wrapper .comment-form textarea,
.contact-form-wrapper .contact-form input,
.contact-form-wrapper .contact-form textarea {
  padding: 18px 25px;
  border-radius: 30px;
  border: 1px solid #f4eefb;
  margin-bottom: 25px;
  width: 100%;
  transition: 0.3s;
}
@media (prefers-reduced-motion: reduce) {
  .comment-form-wrapper .comment-form input,
  .comment-form-wrapper .comment-form textarea,
  .comment-form-wrapper .contact-form input,
  .comment-form-wrapper .contact-form textarea,
  .contact-form-wrapper .comment-form input,
  .contact-form-wrapper .comment-form textarea,
  .contact-form-wrapper .contact-form input,
  .contact-form-wrapper .contact-form textarea {
    transition: none;
  }
}
@media (max-width: 767px) {
  .comment-form-wrapper .comment-form input,
  .comment-form-wrapper .comment-form textarea,
  .comment-form-wrapper .contact-form input,
  .comment-form-wrapper .contact-form textarea,
  .contact-form-wrapper .comment-form input,
  .contact-form-wrapper .comment-form textarea,
  .contact-form-wrapper .contact-form input,
  .contact-form-wrapper .contact-form textarea {
    padding: 12px 25px;
  }
}
.comment-form-wrapper .comment-form input:focus,
.comment-form-wrapper .comment-form textarea:focus,
.comment-form-wrapper .contact-form input:focus,
.comment-form-wrapper .contact-form textarea:focus,
.contact-form-wrapper .comment-form input:focus,
.contact-form-wrapper .comment-form textarea:focus,
.contact-form-wrapper .contact-form input:focus,
.contact-form-wrapper .contact-form textarea:focus {
  border-color: #3763eb;
}
.comment-form-wrapper .comment-form textarea,
.comment-form-wrapper .contact-form textarea,
.contact-form-wrapper .comment-form textarea,
.contact-form-wrapper .contact-form textarea {
  border-radius: 18px;
}
.comment-form-wrapper .comment-form .theme-btn,
.comment-form-wrapper .contact-form .theme-btn,
.contact-form-wrapper .comment-form .theme-btn,
.contact-form-wrapper .contact-form .theme-btn {
  font-weight: 500;
  padding: 18px 90px;
}

.page-banner-section {
  margin-top: 66px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .page-banner-section {
    margin-top: 66px;
  }
}
.page-banner-section .banner-content h2 {
  margin-bottom: 20px;
}
.page-banner-section .banner-content .page-breadcrumb nav ol {
  background: none;
  padding: 0;
}
.page-banner-section .banner-content .page-breadcrumb nav ol li {
  color: rgba(255, 255, 255, 0.7);
  font-size: 20px;
  font-weight: 500;
}
.page-banner-section .banner-content .page-breadcrumb nav ol li::before {
  color: #fff;
}
.page-banner-section .banner-content .page-breadcrumb nav ol li a {
  color: #fff;
}

/*--------------------------------------------------------------
# Recent Blog Posts
--------------------------------------------------------------*/
.recent-blog-posts .post-box {
  transition: 0.3s;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
}
.recent-blog-posts .post-box .post-img {
  overflow: hidden;
  position: relative;
  border-radius: 10px;
}
.recent-blog-posts .post-box .post-img img {
  transition: 0.5s;
}
.recent-blog-posts .post-box .meta {
  margin-top: 15px;
}
.recent-blog-posts .post-box .meta .post-date {
  font-size: 15px;
  font-weight: 400;
  color: var(--color-primary);
}
.recent-blog-posts .post-box .meta .post-author {
  font-size: 15px;
  font-weight: 400;
  color: var(--color-secondary);
}
.recent-blog-posts .post-box .post-title {
  font-size: 24px;
  color: var(--color-secondary);
  font-weight: 700;
  margin: 15px 0 0 0;
  position: relative;
  transition: 0.3s;
}
.recent-blog-posts .post-box .post-content {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.recent-blog-posts .post-box p {
  margin: 15px 0 0 0;
  color: rgba(var(--color-secondary-dark-rgb), 0.7);
}
.recent-blog-posts .post-box .readmore {
  display: flex;
  align-items: center;
  font-weight: 600;
  line-height: 1;
  transition: 0.3s;
  margin-top: 15px;
}
.recent-blog-posts .post-box .readmore i {
  line-height: 0;
  margin-left: 4px;
  font-size: 18px;
}
.recent-blog-posts .post-box:hover .post-title {
  color: var(--color-primary);
}
.recent-blog-posts .post-box:hover .post-img img {
  transform: scale(1.1);
}

/*--------------------------------------------------------------
# Recent Blog Posts
--------------------------------------------------------------*/
.blog-style-2 {
  padding: 0;
  border: none;
}
.blog-style-2 .blog-img {
  padding-bottom: 0px;
  margin-bottom: 30px;
  border-radius: 10px;
}
.blog-style-2 .blog-img img {
  border-radius: 10px;
}
.blog-style-2 .blog-content {
  padding: 0;
}
.blog-style-2 .blog-content h4 {
  margin-bottom: 18px;
}
.blog-style-2 .blog-content p {
  margin-bottom: 20px;
}
.blog-style-2 .blog-content .blog-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.blog-style-2 .blog-content .blog-meta .read-more-btn {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
@media (max-width: 767px) {
  .blog-style-2 .blog-content .blog-meta .read-more-btn {
    font-size: 16px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .blog-style-2 .blog-content .blog-meta .read-more-btn {
    font-size: 18px;
  }
}
.blog-style-2 .blog-content .blog-meta .read-more-btn i {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #eff2f9;
  padding-right: 5px;
  margin-left: 10px;
  font-size: 15px;
}
.blog-style-2 .blog-content .blog-meta .comment {
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 767px) {
  .blog-style-2 .blog-content .blog-meta .comment {
    font-size: 16px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .blog-style-2 .blog-content .blog-meta .comment {
    font-size: 18px;
  }
}
.blog-style-2 .blog-content .blog-meta .comment i {
  margin-right: 10px;
}

/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
:root {
  --color-default: #212529;
  --color-default-rgb: 33, 37, 41;
  --color-background: #ffffff;
  --color-background-rgb: 255, 255, 255;
  --color-primary: #e84545;
  --color-primary-rgb: 232, 69, 69;
  --color-secondary: #32353a;
  --color-secondary-rgb: 50, 53, 58;
  --color-box-background: #ffffff;
  --color-box-background-rgb: 255, 255, 255;
  --color-inverse: #ffffff;
  --color-inverse-rgb: 255, 255, 255;
}

html,
body,
#root {
  height: 100%;
}

.btn:focus, .btn:active {
  outline: none !important;
  box-shadow: none !important;
}

a:focus, a:active {
  outline: none !important;
  box-shadow: none !important;
}

.auth .auth-background-holder {
  height: 100vh;
  min-height: 100%;
}

.auth-main-col,
.auth-background-col {
  position: relative;
}

.auth-main-col {
  margin: auto;
}

.auth-background-col {
  background: rgba(18, 32, 58, 0.6);
}

.auth-background-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.1);
}

.auth-wrapper {
  background: white;
  height: 100vh;
}
.auth-wrapper .auth-body {
  width: 360px;
}
.auth-wrapper .auth-heading {
  font-size: 1.5rem;
}
.auth-wrapper .auth-option {
  font-size: 0.875rem;
}
.auth-wrapper .auth-option > a {
  color: #2a4b88;
}
.auth-wrapper .forgot-password > a {
  color: #2a4b88;
}
.auth-wrapper input:focus {
  box-shadow: none;
}

@media (max-width: 767.98px) {
  .auth-background-col {
    display: none;
  }
}
/**
   * Styles
   **/
.carousel-controlled .carousel-fade {
  background-color: black;
}