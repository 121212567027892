.countdown-timer-wrapper {
  // position: relative;
}

.countdown-timer {
  position: absolute;
  top: 28%;

  width: 100%;

  margin: 0 auto;

  color: #fff;
  text-align: center;

  .heading-count {
    padding: 10px 68px;
    text-align: center;
  }

  .button-group {
    margin-top: 30px;
  }

  &-title {
    //color: #fff;
    text-transform: uppercase;
    margin-bottom: 50px;

    font-weight: 600;
    //font-size: 48px;
    //line-height: 20px;
  }

  &-subtitle {
    //color: #fff;

    font-size: 38px;
    line-height: 20px;
  }

  &-description {
    //color: #fff;

    font-size: 18px;
    line-height: 20px;
  }
}

.time-countdown {
  width: 100%;
}

.time-count div {
  text-align: center;
}

.time-entry {
  display: inline-block;
  margin: 15px;
  padding: 20px;

  color: #fff;

  border-radius: 0px;

  text-align: center;

  font-size: 20px;
  line-height: 22px;
  font-weight: 400;

  &:first-child {
    border-left: none;
  }

  span {
    display: block;

    margin-bottom: 10px;

    color: #fff;

    font-family: "Montserrat", sans-serif;
    font-size: 58px;
    line-height: 45px;
    font-weight: 700;
  }
}

.countdown-social a {
  display: inline-block;
  color: #fff;
  width: 35px;

  // line-height: 35px;
  font-size: 20px;
  text-align: center;
  transition: all 0.3s ease-in-out 0s;
  &:hover {
    color: #007bff;
  }
}

.bg-wraper {
  position: fixed;
  top: 0;
  height: 100%;
  min-height: 700px;
  width: 100%;
}

.bg-wraper {
  background: url("../../../../assets/images/background_countdown.jpg")
    no-repeat center center fixed;
  background-size: cover;
}
