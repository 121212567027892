.testimonial-carousel .owl-nav {
  position: absolute;
  width: 40px;
  height: 100%;
  top: calc(50% - 50px);
  left: -21px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
