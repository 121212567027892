$color_1: #444444;
$color_2: #37517e;
$color_3: #fff;

.countdown-timer .controll-group {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;

  form {
    position: relative;
    width: 500px;
    padding: 6px 10px;
    margin-top: 30px;
    background: #fff;

    border-radius: 50px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.06);
    text-align: left;
    input[type="email"] {
      border: 0;
      padding: 4px 8px;
      width: calc(100% - 100px);
    }
    input[type="submit"] {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      border: 0;
      background: none;
      font-size: 16px;
      padding: 0 20px;
      background: #47b2e4;
      color: $color_3;
      transition: 0.3s;
      border-radius: 50px;
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
      text-transform: uppercase;
      &:hover {
        background: #209dd8;
      }
    }
    input[type="email"]:focus {
      outline: none;
    }
  }

  p {
    margin-top: 24px;
  }
}
